.App {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
  color: white;
}

@font-face {
  font-family: 'Benne-Regular';
  src:url('./fonts/benne-regular/Benne-Regular.ttf.woff') format('woff'),
      url('./fonts/benne-regular/Benne-Regular.ttf.svg#Benne-Regular') format('svg'),
      url('./fonts/benne-regular/Benne-Regular.ttf.eot'),
      url('./fonts/benne-regular/Benne-Regular.ttf.eot?#iefix') format('embedded-opentype'); 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Basic-Sans';
  src:url('./fonts/basic-sans/basicsanssfbold.ttf'),
      url('./fonts/basic-sans/basicsanssfbold.ttf'),
      url('./fonts/basic-sans/basicsanssfitalic.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src:url('./fonts/roboto/Roboto-Black.ttf'),
      url('./fonts/roboto/Roboto-BlackItalic.ttf'),
      url('./fonts/roboto/Roboto-Bold.ttf'),
      url('./fonts/roboto/Roboto-BoldItalic.ttf'),
      url('./fonts/roboto/Roboto-Italic.ttf'),
      url('./fonts/roboto/Roboto-Light.ttf'),
      url('./fonts/roboto/Roboto-LightItalic.ttf'),
      url('./fonts/roboto/Roboto-Medium.ttf'),
      url('./fonts/roboto/Roboto-MediumItalic.ttf'),
      url('./fonts/roboto/Roboto-Regular.ttf'),
      url('./fonts/roboto/Roboto-Thin.ttf'),
      url('./fonts/roboto/Roboto-ThinItalic.ttf')
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-Light.ttf');
  font-weight: 100;
}

